import React from 'react';

const Calendar = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none">
    <path
      d="M5 2.00006H11V1H13V2.00006H16V15.0008H0V2.00006H3V1H5V2.00006ZM2 4.00018V13.0007H14V4.00018H2ZM6 5.00024V7.00035H3V5.00024H6Z"
      fill="#C3C9D2"
    />
  </svg>
);
const Triangle = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none">
    <path
      d="M7.76432 9.12731C7.36555 9.58777 6.65123 9.58777 6.25246 9.12731L3.24521 5.65483C2.68433 5.00719 3.14439 4.00018 4.00114 4.00018L10.0156 4.00018C10.8724 4.00018 11.3324 5.00719 10.7716 5.65483L7.76432 9.12731Z"
      fill="#C3C9D2"
    />
  </svg>
);

export default {
  Calendar,
  Triangle,
};
