import theme from '@/style';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {View, StyleSheet, Image} from 'react-native';
import Text from '@/components/basic/text';
import DigitBall from '@/common-pages/mix-lottery/component/digit-ball';
import {
  COLORS,
  INDEX_TO_O,
  RESULT_INDEX,
} from '@/common-pages/mix-lottery/constant';
import {
  LotteryOrderTicket,
  checkType,
} from '@/common-pages/mix-lottery/mix-lottery-service';
import {BasicObject, SafeAny} from '@/types';
import Tag from '@/common-pages/mix-lottery/component/tag';
import WonResult from '@/components/business/won/won';
import {toPriceStr} from '@/utils';
import LinearGradient from '@/components/basic/linear-gradient/linear-gradient';
const rightIcon = require('@components/assets/icons/me-list-item/right-icon.webp');

const DEFAULT = ['A', 'B', 'C', 'D'];
const SIDE_TYPE = ['Odd', 'Even', 'Big', 'Small'];
const F_P_C_TYPE = ['Fish', 'Prawn', 'Crab'];

const mergeArrayByField = (arr: SafeAny[], field1: string, field2: string) => {
  const merged = {} as BasicObject;
  for (const obj of arr) {
    const key = obj[field1] + '-' + obj[field2]; // 使用字段值组合作为键
    if (!merged[key]) {
      merged[key] = [];
    }
    merged[key].push(obj);
  }
  return merged;
};

const tagInfo = (id: number, betItem: number) => {
  if (id === 1) {
    if (betItem) {
      const TYPE_INDEX = (betItem - 1) % 4;
      const INDEX = Math.trunc((betItem - 1) / 4);
      return {
        type: DEFAULT[INDEX],
        label: SIDE_TYPE[TYPE_INDEX],
      };
    }
  }
  if (id === 9) {
    const TYPE_INDEX = (betItem - 1) % 3;
    return {
      type: 'D',
      label: F_P_C_TYPE[TYPE_INDEX],
    };
  }
  return {};
};

const SattaLottery = ({
  wonCode = [],
  ticketsLists = [],
  awardAmount = 0,
  isQuick = false,
}: {
  wonCode: string[];
  ticketsLists: LotteryOrderTicket[];
  awardAmount: number;
  isQuick?: boolean;
}) => {
  const {t} = useTranslation();

  const targetData = React.useMemo(() => {
    // const res = ticketsLists.filter(item => item.prize === 0);
    return mergeArrayByField(ticketsLists, 'modeName', 'tabName') || {};
  }, [ticketsLists]);

  const getDigitData = (modeID: number, betNo: string) => {
    if (checkType(modeID).isx4) {
      const indexs = RESULT_INDEX.slice(-4);
      return betNo
        .slice(-4)
        .split('')
        .map((item, _i) => {
          return {
            number: item,
            bg: COLORS[indexs[_i]],
          };
        });
    }
    if (checkType(modeID).isx3) {
      const indexs = RESULT_INDEX.slice(-3);
      return betNo
        .slice(-3)
        .split('')
        .map((item, _i) => {
          return {
            number: item,
            bg: COLORS[indexs[_i]],
          };
        });
    }
    if (checkType(modeID).isx2) {
      const indexs = RESULT_INDEX.slice(-2);
      return betNo
        .slice(-2)
        .split('')
        .map((item, _i) => {
          return {
            number: item,
            bg: COLORS[indexs[_i]],
          };
        });
    }
    if (checkType(modeID).isx1) {
      return betNo
        .slice(-1)
        .split('')
        .map((item, _i) => {
          return {
            number: item,
            bg: theme.basicColor.primary,
          };
        });
    }
    return [];
  };

  const renderItem = (lotteryInfo: LotteryOrderTicket, index: number) => {
    if (lotteryInfo.modeID === 9 || lotteryInfo.modeID === 1) {
      const info = tagInfo(lotteryInfo.modeID, lotteryInfo.betItem);
      return (
        <View
          key={index}
          style={[
            theme.margin.topxxs,
            theme.flex.row,
            {gap: theme.paddingSize.xxs},
          ]}>
          {info.type && (
            <Tag label={info.label} backgroundColor={COLORS[info.type]} />
          )}
        </View>
      );
    }
    const list = getDigitData(lotteryInfo.modeID, lotteryInfo.betNo);
    return (
      <View
        key={index}
        style={[
          theme.flex.row,
          theme.flex.centerByCol,
          {gap: theme.paddingSize.xxs},
        ]}>
        {list.map((item, _i) => (
          <DigitBall
            isPrimary={checkType(lotteryInfo.modeID).isX}
            key={_i}
            digit={item.number}
            bg={item.bg}
            type={'A'}
          />
        ))}
      </View>
    );
  };

  const status = React.useMemo(() => {
    return !wonCode.every(v => v.indexOf('-') === -1)
      ? 0
      : ticketsLists.every(v => v.ticketStatus === 2) || isQuick
      ? 4
      : 3;
  }, [wonCode, ticketsLists, isQuick]);

  const winList = React.useMemo<BasicObject>(() => {
    const list = ticketsLists.filter(item => item.prize > 0);
    return mergeArrayByField(list, 'modeName', 'tabName') || {};
  }, [ticketsLists]);

  return (
    <View style={[theme.margin.tops]}>
      <View
        style={[
          theme.flex.row,
          theme.flex.centerByCol,
          theme.flex.between,
          styles.drawContainer,
          {
            backgroundColor: '#3B3F49',
          },
        ]}>
        <View style={[theme.flex.flex1, theme.flex.centerByRow]}>
          <Text size="medium" fontFamily="fontInterBold">
            {t('bets-detail.label.drawRes').split(' ').join('\n')}：
          </Text>
        </View>
        <View style={[{gap: theme.paddingSize.xxs}]}>
          {wonCode.map((item, i) => (
            <View
              style={[
                theme.flex.row,
                theme.flex.between,
                theme.flex.centerByCol,
              ]}
              key={i}>
              <Text fontFamily="fontInterBold">
                {INDEX_TO_O[i + 1]}-prize：
              </Text>
              <View
                style={[
                  theme.flex.row,
                  theme.margin.leftxxs,
                  {gap: theme.paddingSize.xxs},
                ]}>
                {item.split('').map((pie, _i) => (
                  <DigitBall
                    disabled={
                      RESULT_INDEX.slice(-item.split('').length)[_i] ===
                      'UNUSED'
                    }
                    digit={pie}
                    type={RESULT_INDEX.slice(-item.split('').length)[_i]}
                    key={_i}
                  />
                ))}
              </View>
            </View>
          ))}
        </View>
      </View>
      {status === 4 && awardAmount > 0 && (
        <WonResult
          awardAmount={awardAmount}
          style={[theme.margin.topxxl, theme.margin.btms]}
        />
      )}
      {status === 4 && awardAmount === 0 && (
        <View style={[theme.padding.topxxl, theme.padding.btmm]}>
          <Text textAlign="center" fontSize={15} primary>
            {t('game-page.label.guessWrong')}
          </Text>
        </View>
      )}
      {status === 3 && (
        <View style={[theme.padding.topxxl, theme.padding.btmm]}>
          <Text textAlign="center" fontSize={15} primary>
            {t('game-page.label.ticketSetting')}
          </Text>
        </View>
      )}
      {Object.keys(winList).length > 0 && (
        <View style={[{gap: theme.paddingSize.xxs}, theme.margin.tops]}>
          {Object.keys(winList).map((item, _i) => {
            const data = winList[item] as LotteryOrderTicket[];
            const totalPrize = data.reduce(
              (total, obj) => total + obj.prize,
              0,
            );
            const totalBet = data.reduce(
              (total, obj) => total + (obj.amount + obj.fee),
              0,
            );
            return (
              <View
                key={_i}
                style={[
                  theme.flex.row,
                  {backgroundColor: '#3B3F49'},
                  theme.borderRadius.m,
                  theme.padding.lrl,
                  theme.padding.tbxxs,
                ]}>
                <View style={[theme.flex.flex1]}>
                  <Text fontFamily="fontInterBold">{item}:</Text>
                  <View
                    style={[
                      theme.flex.row,
                      theme.flex.wrap,
                      {gap: theme.paddingSize.s},
                    ]}>
                    {data.map((pie, _ii) => renderItem(pie, _ii))}
                  </View>
                </View>
                <View style={[theme.flex.alignEnd]}>
                  <Text fontFamily="fontInterBold">
                    {t('game-page.label.bet')} {toPriceStr(totalBet)}
                  </Text>
                  <View style={[theme.flex.row, theme.flex.centerByCol]}>
                    <Text accent fontFamily="fontInterBold">
                      {t('bets-detail.label.won')}{' '}
                    </Text>
                    <Text
                      fontFamily="fontInterBold"
                      size="large"
                      color={theme.basicColor.primary}>
                      {toPriceStr(totalPrize, {fixed: 2})}
                    </Text>
                  </View>
                </View>
              </View>
            );
          })}
        </View>
      )}
      <View style={[theme.margin.topxs]}>
        {Object.keys(targetData)
          .slice(0, 3)
          .map((item, _i) => {
            const valuesData = targetData[item].slice(0, 6);
            return (
              <View
                key={_i}
                style={[styles.item, {backgroundColor: '#3B3F49'}]}>
                <Text fontFamily="fontInterBold">{item}:</Text>
                <View
                  style={[
                    theme.flex.row,
                    {gap: theme.paddingSize.s},
                    theme.flex.wrap,
                  ]}>
                  {valuesData.map((pie: LotteryOrderTicket, _ii: number) =>
                    renderItem(pie, _ii),
                  )}
                </View>
              </View>
            );
          })}
      </View>
      {(Object.keys(targetData).length > 3 ||
        Object.keys(targetData).find(item => targetData[item].length > 6)) && (
        <LinearGradient colors={['#212329', '#16181B']} style={[styles.over]}>
          <View style={[theme.flex.row, theme.flex.center, theme.padding.btml]}>
            <Text fontFamily="fontInterBold">View Details</Text>
            <Image
              source={rightIcon}
              style={[theme.icon.s, theme.margin.lefts]}
            />
          </View>
        </LinearGradient>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  drawContainer: {
    paddingHorizontal: 12,
    paddingVertical: 8,
    backgroundColor: '#F4F4F4',
  },
  item: {
    marginTop: 2,
    backgroundColor: '#F6F7FB',
    paddingHorizontal: 12,
    borderRadius: 8,
    paddingVertical: 4,
  },
  over: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    paddingTop: 80,
  },
});

export default SattaLottery;
