import {View, StyleSheet} from 'react-native';
import Text from '@/components/basic/text';
import React from 'react';
import Ball from '@/common-pages/color/components/ball';
import theme from '@/style';
import {ALL_BALLS} from '@/common-pages/color/constant';
import {useTranslation} from 'react-i18next';
import Won from '../won/won';

export interface ColorResultProps {
  isOpen?: boolean;
  digitResult?: string;
  colorResult?: string;
  subsetList?: {
    amount: number;
    colorResult: string;
    selectValue: string;
    awardAmount: number;
  }[];
}

const ColorResult = ({
  isOpen,
  subsetList = [],
  colorResult,
  digitResult,
}: ColorResultProps) => {
  const winList = React.useMemo(() => {
    return subsetList?.filter(item => item.awardAmount > 0);
  }, [subsetList]);
  const {i18n} = useTranslation();
  return (
    <View style={[theme.margin.tops]}>
      <View
        style={[
          theme.flex.row,
          theme.flex.centerByCol,
          theme.flex.between,
          styles.drawContainer,
        ]}>
        <Text size="medium" fontFamily="fontInterBold" color={'var(--T2)'}>
          {i18n.t('bets-detail.label.drawRes')}：
        </Text>
        <View>
          <Ball
            onlyColor={false}
            digit={isOpen ? digitResult! : '-'}
            color={isOpen ? colorResult : '*'}
            ballSize={32}
            fontColor={isOpen ? '#000' : '#fff'}
            fontSize={16}
          />
        </View>
      </View>
      <View
        style={[
          {gap: theme.paddingSize.s},
          winList?.length > 0 && theme.margin.tops,
        ]}>
        {winList?.map((item, _i) => {
          const color = !isNaN(Number(item.selectValue))
            ? ALL_BALLS.find(pie => pie.digit === item.selectValue)?.color
            : item.selectValue;
          return (
            <Won
              key={_i}
              result={
                <Ball
                  onlyColor={isNaN(Number(item.selectValue))}
                  digit={item.selectValue}
                  color={color}
                  ballSize={28}
                  fontColor={!isNaN(Number(item.selectValue)) ? '#000' : '#fff'}
                  fontSize={isNaN(Number(item.selectValue)) ? 6 : 14}
                />
              }
              bet={item.amount}
              awardAmount={item.awardAmount}
            />
          );
        })}
      </View>
      <View
        style={[
          theme.flex.row,
          theme.flex.centerByRow,
          theme.margin.tops,
          {gap: theme.paddingSize.s},
        ]}>
        {subsetList?.map((item, _ii) => {
          const color = !isNaN(Number(item.selectValue))
            ? ALL_BALLS.find(pie => pie.digit === item.selectValue)?.color
            : item.selectValue;
          return (
            <Ball
              key={_ii}
              onlyColor={isNaN(Number(item.selectValue))}
              digit={item.selectValue}
              color={color}
              ballSize={28}
              fontColor={!isNaN(Number(item.selectValue)) ? '#000' : '#fff'}
              fontSize={isNaN(Number(item.selectValue)) ? 6 : 14}
            />
          );
        })}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  drawContainer: {
    paddingHorizontal: 12,
    paddingVertical: 8,
    backgroundColor: 'var(--image)',
  },
});

export default ColorResult;
